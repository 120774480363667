@import '../../../../styles/scss/abstracts/mixins';

.FilterPanel {
	position: relative;

	background-color: #E7E7E7;
	// border-top: 2px solid #0003;
	// border-bottom: 1px solid #0003;

	padding: 1rem 0;

	overflow: hidden;
	transition: 0.2s;

	.filterBy {
		padding-right: 0.5rem;
		font-size: 2rem;
		// font-weight: 500;
	}

	.btn-close {
		position: absolute;
		top: 0.5rem;
		right: 2rem;

		height: 4rem;
		width: 4rem;

		font-size: 1.6rem;
	}
}

.FilterForm {
	display: flex;
	justify-content: center;
	// align-items: center;

	// padding: 2rem 0;

	min-height: 8rem;
	max-height: 12rem;

	// overflow: auto;

	
	.Select {
		margin-left: 2rem;
		font-size: 1.4rem;

		// width: 16rem;
		min-width: 16rem;
		max-width: 18rem;
		// width: 100%;

		// max-height: 10rem;

		// overflow-y: auto;

		// @include breakpoint('x-large') {
		// 	width: 16rem;
		// }
		
		position: relative;
		z-index: 900;
	}

	label {
		font-size: 1.4rem;
		margin-left: 2rem;
		margin-bottom: 1rem;
		display: inline-block;
	}

	.btn-clear {
		margin-top: 2.75rem;
		margin-left: 3rem;

		background-color: white;
		height: 4rem;
		border-radius: 2rem;

		padding: 1rem 2rem;
	}
}