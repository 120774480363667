// ==================== IMPORTS ==================== //

@font-face {
	font-family: 'Graphik';
	src: url('../../../assets/fonts/graphik-free/GraphikThin.otf') format('opentype');
	font-weight: 100;
	font-style: normal;
}
@font-face {
	font-family: 'Graphik';
	src: url('../../../assets/fonts/graphik-free/GraphikLight.otf') format('opentype');
	font-weight: 300;
	font-style: normal;
}
@font-face {
	font-family: 'Graphik';
	src: url('../../../assets/fonts/graphik-free/GraphikRegular.otf') format('opentype');
	font-weight: 400;
	font-style: normal;
}
@font-face {
	font-family: 'Produkt';
	src: url('../../../assets/fonts/Produkt-Light.otf') format('opentype');
	font-weight: 300;
	font-style: normal;
}

// ==================== FONT FAMILY ==================== //

body, input, textarea, button, h2, h1, h3 {
	font-family: $primaryFont;
	letter-spacing: 0.01em;
}

// h1, h3 {
// 	font-family: $secondaryFont;
// }

// ==================== TYPOGRAPHY ==================== //

p, input, textarea {
	font-weight: 300;
	letter-spacing: 0.01em;
	line-height: 1.5;
}

h1 {
	font-weight: 100;
}

h2 {
	font-weight: 400;
	line-height: 1.3;
  letter-spacing: 0.01em;
}

h3 {
	font-weight: 400;
	line-height: 1.3;
  letter-spacing: 0.01em;
}

// button {
// 	// letter-spacing: 0.05em;
// }