@import 'styles/scss/abstracts/variables';

.GridItemEditModal {
	.ReactModal__Content {
		min-width: 60rem;
		max-width: 95%;
		// padding-right: 6rem !important;

		.Modal-content {
			margin-top: 2rem;

			// display: flex;
			// justify-content: space-between;
		}

		.content {
			display: flex;
			justify-content: space-between;
		}

		.properties {
			font-size: 1.5rem;
			font-weight: normal;

			margin-right: 1.5rem;
		}

		h2 {
			margin-bottom: 1rem;
		}

		.property {
			display: flex;
			// align-items: center;

			margin-bottom: 0.75rem;

			&.newLine {
				flex-direction: column;

				.property-label {
					margin-bottom: 0.5rem;
				}

				// .input {
				// 	margin-left: 1rem;
				// };
			}

			button {
				font-size: 1.5rem;
				text-align: left;
			}

			&-label{
				padding-right: 1rem;
				display: flex;
				// font-weight: 600;
				text-transform: uppercase;

				// .btn-edit{
				// 	font-weight: 600;
				// }
			}

			// &--activityType,
			&--people,
			&--opportunityDescription,
			&--businessValue {
				margin-bottom: 2.5rem;
			}

		}

		// :not(h2) .input {
		// 	min-width: 25rem;
		// 	max-width: 45rem;
		// }

		.input {
			form, .Select {
				min-width: 18rem;
				max-width: 45rem;
				
				> * {
					width: 100%;
				}

				display: flex;
			}
			// text-transform: lowercase;

			// margin-bottom: 0;
			// .InputForm {
			// 	min-width: 30rem;
			// 	max-width: 45rem;
			// }

			.btn-edit {
				position: relative;
				// text-transform: lowercase;
				// border: 1px solid red;

				svg {
					font-size: 1.2rem;
					// margin-left: 0.5rem;
					// transform: translateY(-0.5rem);
					visibility: hidden;

					position: absolute;
					top: -0.25rem;
					right: -1.75rem;
				}

				&:hover,
				&:focus {
					svg {
						visibility: visible;
					}
					color: $hoverAccent;
				}
			}

			input,
			textarea {
				width: 100%;
				resize: vertical;
			}

			.btn-save {
				font-size: 2.4rem;
				margin-left: 0.5rem;
			}

			.property-values {
				padding-left: 1rem;
			}
		}

		h2 {
			margin-bottom: 2rem;

			.btn-edit,
			input,
			textarea {
				font-size: 2.2rem;
			}

			.input {
				min-width: 0;
				max-width: 100%;
				width: 100%;
				// font-size: 2.2rem;
				// font-weight: 600;
			}
		}
		
		p, li {
			.btn-edit,
			input,
			textarea {
				font-size: 1.5rem;
			}
		}
		
		h3 {
			margin-top: 2rem;
			margin-bottom: 0.5rem;
		}
	}


	.actions {
		margin-bottom: 2rem;

		h4 {
			margin-bottom: 0.5rem;

			font-size: 1.6rem;
			font-weight: normal;

			text-transform: uppercase;
		}

		button {
			&:hover {
				color: $hoverAccent;
			}
		}

		button:not(.btn-addProperty) {
			border: #BDBDBD 1px solid;
			border-radius: 0.5rem;
			// background-color: rgba(0, 0, 0, 0.02);

			width: 14rem;
			// height: 3rem;
			margin-bottom: 0.75rem;

			text-align: left;
			padding: 0.5rem 0.5rem;

			font-size: 1.4rem;
		}

		.btn-addProperty {
			padding-left: 0.75rem;

			font-size: 1.4rem;

			display: flex;
			align-items: center;
			
			span {
				transform: translateY(0.2rem)
			}

			svg {
				margin-right: 0.75rem;
			}
		}

		&--actions {
			margin-top: 2rem;
		}
	}

	.delete {
		margin-top: 0.5rem;
	}

	.confirmDelete {
		display: flex;
		flex-direction: column;
	}


	.btn-arrow {
		position: absolute;
		bottom: 0.4rem;
		right: 0.4rem;
		// transform:rotate(-45deg)

		font-size: 1.5rem;

		display: flex;
		align-items: center;

		svg {
			margin-left: 0.5rem;
		}

		&:hover {
			color: $hoverAccent;
		}
	}
}