@import '../../../styles/scss/abstracts/variables';

.Menu {
	.wrapper {
		width: calc(81% - 0.5rem);
		max-width: unset;
		// transform: translateX(-1rem);
    padding-left: 2rem;
		padding-right: 0.5rem;
	}


	&-top {
		display: flex;
		justify-content: space-between;
		align-items: center;
	
		// margin-left: 4rem;
	
		padding: 0.5rem;
		// padding-left: 4rem;
		// box-shadow: 0 10px 10px -10px #0003;
	
		.left {
			flex-basis: calc((1 / 3) * 100%);
		}
	
		.right {
			flex-basis: calc((1 / 3) * 100%);
	
			display: flex;
			justify-content: flex-end;
			align-items: center;
	
			// position: relative;
	
			padding-right: 2rem;

			// img {
			// 	width: 2.5rem;
			// 	margin-left: 1.25vw;
			// }

			.btn-menu {
				width: 2.5rem;
				margin-left: 2.5rem;
			}

			// .icon-filter {
			// 	width: 2.4rem;
			// }
		}
		
		.notifications {
			flex-basis: calc((1 / 3) * 100%);
	
			display: flex;
			justify-content: center;

			.notification {
				display: flex;
				align-items: center;
			}
	
			span {
				margin-right: 1rem;
				font-size: 1.8rem;
			}
	
			button {
				margin-right: 1rem;
			}
		}
	
		.controls {
			display: flex;
			// margin: 0 1.5rem;
	
			// li {
			// 	&:not(:last-child) {
			// 		margin-right: 0.5rem;
			// 	}
			// }
		}
	}

	.dropdown-user {
		display: flex;
		align-items: center;
	}

	.userAvatar {
		height: 3rem;
		margin-right: 0.5rem;
	}

	.nav {
		display: flex;
		align-items: center;

		font-size: 1.8rem;
		color: black;

		span {
			padding: 0 0.5rem;
		}

		.organization {
			display: flex;
			align-items: center;

			img {
				width: 3.5rem;
				margin-right: 0.75rem;
			}
		}

		a:hover {
			color: $hoverAccent;
		}
	}
}