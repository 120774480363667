.ReactTable {
	font-size: 1.4rem;

	max-width: 100rem;
	// overflow-x: scroll;
	overflow-x: auto;

	display: inline-block;
	border-spacing: 0;
	border: 1px solid black;

	select {
		max-width: 98%;
	}

	.tr {
		&:last-child {
			.td {
				border-bottom: 0;
			}
		}
	}

	.th,
	.td {
		margin: 0;
		padding: 0.5rem;
		border-bottom: 1px solid black;
		border-right: 1px solid black;

		overflow: hidden;

		&:last-child {
			border-right: 1px solid black;
		}
	}

	.th {
		font-weight: bold;
	}
}