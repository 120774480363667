@import '../../../styles/scss/abstracts/variables';

.DotRow {
	position: relative;
	// height: 100%;
	display: flex;

	min-height: 3rem;

	.type-select {
		position: absolute;
		z-index: 100;
		left: 0.5rem;
		top: 12.25rem;
		width: 9.25%;

		font-size: 1.5rem;
		text-transform: uppercase;
	}
}

// .DotColumn {
// 	position: absolute;
// 	// border: 1px solid red;
// 	// height: 100%;
// 	// height: 10rem;

// }

.DotRow {
	padding-bottom: 1rem;

	&:not(.DotRow-activityType) thead {
		// border: 1px solid red;
		display: none;
	}

	&-activityType {
		min-height: 17rem;
	}
}


.DotRowTable {
	position: relative;

	border-collapse: collapse;

	// thead {
	// 	height: 10rem;
	// }
	&-container {
		position: absolute;
    // left: 0;
	}


	th {
		// transform: rotate(270deg);
		// height: 10rem;
		// width: 0;
		width: 10rem;
		writing-mode: vertical-rl;
		transform: rotate(180deg);

		display: flex;
		justify-content: flex-start;
		align-items: center;
		text-align: left;

		padding: 1.25rem;

		height: 12rem;

		font-weight: normal;
		// padding-bottom: 2rem;
		// span {
		// 	// position: absolute;
		// 	// overflow: visible;
		// 	writing-mode: vertical-lr;
		// 	transform: rotate(180deg);
		// }

		// position: relative;

	}

	thead {
		position: relative;
		height: 13rem;
		// padding-bottom: 2.5rem;

		background-color: #001a176e;
		color: white;
		
		tr:first-child {
			// hide the main header row
			display: none !important;
		}

		th:first-child {
			background-color: $backgroundPrimary;
			position: relative;

			&::after {
				content: '';
				position: absolute;
				top: -1.25rem;
				left: 0;
				width: 110%;
				height: 1.25rem;
				background-color: $backgroundPrimary;
			}
		}

		&::after {
			content: '';
			position: absolute;
			bottom: 0;
			left: 0;
			width: 110%;
			height: 1.25rem;
			background-color: $backgroundPrimary;
		}
	}

	td {
		display: flex;
		align-items: center;

		position: relative;

		padding: 0; 
   	margin: 0;

		//  overflow: visible;

		// y-axis labels
		&:first-child {
			justify-content: flex-end;
			font-size: 1.5rem;
      overflow: hidden;

      padding: 0.2rem 0.75rem 0.2rem 0.2rem;
		}

		&:not(:first-child) {
			// border: 1px solid red;
			text-align: center;
			justify-content: center;

			// dotted line
			&:before {
				content: " ";
				position: absolute;
				z-index: -10;
				top: 50%;
				left: 0;
				border-bottom: 1px dotted #1116;
				width: 100%;
			}
		}

		// dotted line
		// &:nth-child(2) {
		// 	&:before {
		// 		width: 60%;
		// 		left: 40%;
		// 	}
		// }

		// &:last-child {
		// 	&:before {
		// 		width: 60%;
		// 	}
		// }
	}


	tbody {
		tr/* :not(:first-of-type) */ {
			// border: 1px solid red;
			height: 3.6rem;
			// position: relative;

			&:first-child {
				// border: 1px solid red;
				margin-top: 1rem;
			}
		}
	}

	// thead {
	// 	tr {
	// 		height: unset;
	// 	}
	// }

	.tr-label {
		text-align: right;
    overflow-wrap: break-word;
    max-height: 100%;
    line-height: 1.1;

    max-width: 100%;
	}

}


.Dot {
	font-size: 1.4rem;

	// background-color: $backgroundPrimary;
	background-color: #ffffff;
	border: 1px solid #ffffff;
	border-radius: 50%;

	width: 1.9rem;
	height: 1.9rem;

	display: flex;
	justify-content: center;
	align-items: center;

	position: relative;

	&.rectangle {
		border-radius: 0.5rem;
		width: 100%;
		height: 45%;
		// border-left: none;
		// border-right: none;
		border: none;

		background-color: #37B7AB;

		&:hover,
		&.active {
			background-color: $hoverAccent;
		}
	}

	&:hover {
		// background-color: #ffffffC0;
		color: $hoverAccent;
	}

	&.active {
		// background-color: #ffffffff;
		color: $hoverAccent;
		// border-color: $hoverAccent;
	}

	button {
		position: absolute;
		left: 0;
		top: 0;
		// right: 0;
		// bottom: 0;
		width: 100%;
		height: 100%;
		z-index: 100;
	}
}