// fonts
$primaryFont: "Graphik", sans-serif;
$secondaryFont: "Produkt", sans-serif;

// colors
	// background colors
	$backgroundPrimary: #fbf9f7;
	$backgroundSecondary: black;
	$backgroundPrimaryDarkened: #e7e7e7;

	// accent colors
	$errorColor: #e16c1c;
	$hoverAccent:#EF6C00;
	$backgroundSeparator: #A7A7A7;

	// text colors
	// $headingColor: #454443;
	$headingColor: black;
	// $textColor: #5b5a59;
	$textColor: black;
	$textColorWhite: white; 
	$textColorGrey: #464444;

	// button colors
	$formButtonColor: #454443;
	$formButtonHover: #B6B4B3;

	$arrowColor: #555555;