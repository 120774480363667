@import '../../styles/scss/abstracts/variables';

.VentureMappingTool {
	// background-color: $backgroundPrimary;

	position: relative;

	// margin: 4rem 0 4rem;

	// padding-bottom: 2rem;

	// min-width: 130rem;
	// width: 140rem;
	width: 100%;
	

	.background-main {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;

		z-index: -10000;

		background-color: $backgroundPrimary;
	}

	.tabs {
		// background-color: $backgroundPrimary;
		background-color: black;

		display: grid;
		grid-template-columns: repeat(5, 1fr);
		gap: 0 1.25%;

		padding: 0 1.5%;

		li {
			text-align: center;
		}

		button {
			background-color: $backgroundPrimaryDarkened;
			padding: 1rem 0;
			width: 100%;

			border-radius: 0.75rem 0.75rem 0 0;

			text-transform: uppercase;
			color: #757272;

			&.active {
				background-color: $backgroundPrimary;
				color: black;
			}

			&:hover {
				color: $hoverAccent;
			}
		}
	}

	.rows {
		&-container {
			height: 100%;
			width: 100%;

			position: relative;

			overflow-y: auto;
		}

		position: relative;

		// padding-right: 2rem;
		// margin-bottom: 2rem;

		height: 100%;
		width: 100%;

	}

	.col-bg {
		position: absolute;
		z-index: -1000;
	}

	// .react-grid-item {
	// 	transition: none;
	// }

	// .react-grid-layout {
	// 	transition: none;
	// }

	// .react-transform-wrapper {

	// }
}