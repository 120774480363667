@import '../../../styles/scss/abstracts/variables';

.GridItem {
	// border: 1px solid rgba(153, 153, 153, 0.308);

	display: flex;
	align-items: center;
	justify-content: center;
	

	text-align: center;

	// overflow: hidden;

	position: relative;
	// z-index: 100;

	&:not(.GridItem-Phases) {
		background-color: hsla(0, 0%, 100%, 0.5);

		border-radius: 0.5rem;

		&:hover {
			// background-color: #ffffff80;
			color: $hoverAccent;
		}
	}

	&-Phases {
		border: none;

		.content {
			display: flex;
			align-items: center;
			justify-content: center;

			text-transform: uppercase;

			p {
				font-weight: normal;
			}
		}
		&:nth-child(n+4) {
			// border: 1px solid red;
			font-size: 1.4rem;
		}
	}



	&-heading {
		background: none;
	}

	&.active {
		background-color: #ffffffd0;
	}

	.modalButton {
		position: absolute;
		width: 100%;
		height: 100%;
	}

	.content {
		width: 100%;
		height: 90%;

		overflow: hidden;

		padding: 0.3rem 0.5rem;
		
		p {
			font-weight: normal;
			font-size: 1.4rem;

			color: inherit;
			// line-height: 1.5;

			// width: 100%;
			// height: 100%;

			// white-space: nowrap;
		}
	}
}

.react-resizable-handle {
	&:hover {
		background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2IDYiIHN0eWxlPSJiYWNrZ3JvdW5kLWNvbG9yOiNmZmZmZmYwMCIgeD0iMHB4IiB5PSIwcHgiIHdpZHRoPSI2cHgiIGhlaWdodD0iNnB4Ij48ZyBvcGFjaXR5PSIxIj48cGF0aCBkPSJNIDYgNiBMIDAgNiBMIDAgNC4yIEwgNCA0LjIgTCA0LjIgNC4yIEwgNC4yIDAgTCA2IDAgTCA2IDYgTCA2IDYgWiIgZmlsbD0iI2VmNjkwMCIvPjwvZz48L3N2Zz4=') !important;
	}
}

.react-grid-placeholder {
	background-color: rgba(239, 108, 0, 1) !important;

	border-radius: 0.5rem;
}