// ==================== STRIP STYLES ==================== //

body {
	margin: 0;
	// overflow-y: hidden;
}

// Strip default margin/padding
h1,
h2,
h3,
h4,
h5,
p,
ul,
li,
button {
	margin: 0;
	padding: 0;
}

// Strip ul default styling
ul {
  list-style: none;
}

// Strip anchor tag default styling
a {
  text-decoration: none;
  color: inherit;
}

// Strip button default styling (and add pointer cursor)
button {
	border: none;
	background: none;
	cursor: pointer;
}


// ==================== COLOURS ==================== //

body {
	color: $textColor;
	background-color: $backgroundPrimary;
}

button, p {
	color: $textColor;
}

h1, h2, h3, h4, h5, h6 {
	color: $headingColor;
}

// main {
// 	background-color: $backgroundSecondary;
// }

// ==================== GLOBAL STYLES ==================== //

html {
	scroll-behavior: smooth; // smooth scrolling (needs to be on root html)
}

.wrapper {
	width: 90%;
	max-width: 1400px;
	margin: 0 auto;
}

.gridItem {
	border: 1px solid rgba(153, 153, 153, 0.308);
	background-color: #ffffff60;

	display: flex;
	align-items: center;
	justify-content: center;

	text-align: center;

	overflow: hidden;

	&-heading {
		background: none;
	}
}

.btn {
	&:not(.btn-form):hover,
	&:not(.btn-form).active {
		color: $hoverAccent;

		svg {
			fill: $hoverAccent;
			// stroke: $hoverAccent;
			path {
				fill: $hoverAccent;

				&[stroke] {
					stroke: $hoverAccent;
				}
			}
		}
	}

	&-control {
		color: $textColor;
	}


	&-form {
		font-size: 1.5rem;
		// text-transform: uppercase;

		border-radius: 10rem;

		color: white;
		background-color: $hoverAccent;

		display: flex;
		align-items: center;

		padding: 1rem 2rem;


		&:hover, &:active {
			color: white;
			// text-decoration: none;
			background-color: lighten($hoverAccent, 10);
		}
		&:active {
			// text-decoration: underline;
		}

		svg {
			margin-left: 0.5rem;
			width: 1.6rem;
			// height: 2rem;
			min-width: 1.6rem;
		}

		&--white {
			color: black;
			background-color: $textColorWhite;

			&:hover {
				color: $hoverAccent;
				text-decoration: none;
				background-color: $textColorWhite;

				svg {
					fill: $hoverAccent;

					path {
						fill: $hoverAccent;
					}
				}
			}

		}
	}


	// &.active {
	// 	img {
	// 		filter: opacity(0.1);
	// 	}
	// }

	&-disabled {
		// &:hover {
		// 	color: lighten($hoverAccent, 35);
		// }
		&:active {
			opacity: 0.4;
		}
	}
}

.Link {
	text-decoration: underline;

	&:hover {
		color: lighten($textColor, 20);
	}
}

.msg {
	font-size: 1.4rem;
	&-error {
		color: $errorColor;
	}
}

.error {
	color: $errorColor;
	font-size: 1.2rem;
}

.react-transform-component {
	width: unset !important;
	height: unset !important;
	// transform: translate();
}

.react-transform-element {
	width: unset !important;
	height: unset !important;
}

.react-transform-content {
	min-height: 100%;
}

img {
	max-width:  100%;
	max-height: 100%;
	display: block;
}


.App {
	min-height: 100vh;
	position: relative;

	// padding-bottom: 2rem;
}

header {
	background-color: black;

	.wrapper {
		display: flex;
		justify-content: space-between;
	}

	.logoTitle {
		display: flex;
		align-items: center;

		padding: 1rem;

		img {
			// width: 5rem;
			margin-right: 1rem;
			height: 6rem;
		}
	}

	.menu {
		display: flex;
		align-items: center;

		.dropdown {
			display: flex;
			align-items: center;

			&-list {
				margin-top: 0.75rem;
			}
		}

		.dropdown-user {
			display: flex;
			align-items: center;
			white-space: nowrap;

			color: white;

			.userAvatar {
				width: 4rem;
			}

			img,
			svg {
				margin-right: 0.5rem;
			}

			&:hover {
				color: $hoverAccent;
			}
		}

		> * {
			padding: 0 1rem;
		}
	}

	span {
		color: white;
	}

	.fivepointsLogo {
		height: 2.8rem;
		margin-left: 1rem;
	}
}

footer {
	// height: 3rem;

	position: absolute;
	bottom: 0;
	width: 100%;

	display: flex;
	justify-content: flex-end;
	// align-items: center;
}

.copyright {
	// position: absolute;
	// bottom: 0;

	// text-align: right;
	// font-size: 1.2rem;
	// // margin: 1rem 2rem;

	// background-color: #FFF9;
	// padding: 0.25rem 0.5rem;

	// margin-right: 2rem;

	display: flex;
	align-items: center;
	white-space: nowrap;

	font-size: 1.4rem;

	margin-left: 0.5rem;
}

.userAvatar {
	border-radius: 50%;
}

.dropdown {
	position: relative;
	// z-index: 1000;

	&-add {
		margin-right: 1.25rem;
	}

	&-button {
		// padding-left: 1.5rem;
		color: $textColor;
		font-size: 1.6rem;
	}
	&-list {
		background-color: $backgroundPrimary;
		
		position: absolute;
		right: -1rem;
		top: 4rem;
		
		z-index: 1001;

		display: flex;
		flex-direction: column;

		// min-width: 12rem;

		padding: 1rem 2rem;
		padding-bottom: 0;

		border-radius: 0.5rem;
		box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.25);

		button,
		a {
			font-size: 1.5rem;
			margin-bottom: 1.5rem;

			white-space: nowrap;
		}
		a {
			display: inline-block;
			font-weight: 300;
			letter-spacing: 0.05em;
		}
	}

	li {
		button {
			display: flex;
			align-items: center;

			span {
				transform: translateY(0.2rem)
			}
			
			svg {
				margin-right: 0.75rem;
			}
		}
	}
}

.backgroundMask {
	height: 1.25rem;
	width: 95%;
	background-color: $backgroundPrimary;
}

.confirmDelete {
	z-index: 400;
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;

	display: flex;
	align-items: center;
	justify-content: center;

	background-color: #FFFB;

	button {
		background-color: $hoverAccent;
		font-size: 1.8rem;
		padding: 1.2rem 2rem;

		&:hover {
			background-color: lighten($hoverAccent, 10);
			text-decoration: none;
			color: white;
		}
	}
}

.pageBackground {
	background-color: $backgroundSecondary;

	position: absolute;
	z-index: -100;
	top: 0;
	left: 0;

	width: 100%;
	height: calc(100% + 6rem);
}