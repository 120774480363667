.formError {
	color: red;
	font-size: 1.2rem;
}

.ImportForm {
	display: flex;
	flex-direction: column;

	.input {
		margin: 0.5rem 0;
	}

	select {
		margin-left: 1rem;
		margin-top: 1rem;

		font-size: 1.6rem;
	}

	input[type="radio"] {
		margin-right: 0.5rem;
	}

	button[type="submit"] {
		align-self: flex-end;
		text-transform: uppercase;
		font-size: 1.6rem;
		padding: 1.25rem 3rem;
	}


	// button {
	// 	align-self: center;

	// 	font-size: 1.4rem;

	// 	border: 2px solid rgb(203, 220, 255);
	// 	border-radius: 0.5rem;

	// 	background-color: rgb(222, 230, 247);

	// 	padding: 0.5rem 1rem;

	// 	&:hover {
	// 		background-color: darken(rgb(222, 230, 247), 10);
	// 	}
	// 	&:active {
	// 		border-color: darken(rgb(203, 220, 255), 15);
	// 	}
	// }
}