@import '../../styles/scss/abstracts/variables';

.HomePage {
	margin: 0 auto;
	width: 90%;
	max-width: 120rem;
	margin: 0 auto;

	color: $textColorWhite;

	// h2, h4, span, p, button {
	* {
		color: $textColorWhite;
	}

	.projectList a {
		* {
			color: black;
		}
	}

	// button, svg {
	// 	color: $textColorWhite;
	// 	fill: $textColorWhite;
	// 	path {
	// 		fill: $textColorWhite;
	// 	}
	// }

	// .btn-form {
	// 	color: black;
	// 	background-color: $textColorWhite;

	// 	display: flex;
	// 	align-items: center;

	// 	padding: 1rem 2rem;

	// 	&:hover {
	// 		color: $hoverAccent;
	// 		text-decoration: none;
	// 		background-color: $textColorWhite;

	// 		svg {
	// 			fill: $hoverAccent;

	// 			path {
	// 				fill: $hoverAccent;
	// 			}
	// 		}
	// 	}

	// 	svg {
	// 		margin-left: 0.5rem;
	// 		width: 1.6rem;
	// 		// height: 2rem;
	// 		min-width: 1.6rem;
	// 	}
	// }

	// svg, img {
	// 	// position: relative;
	// 	// z-index: 101;
	// 	background-color: white;
	// }

	// background-color: $backgroundSecondary;

	.userName {
		padding-top: 2rem;

		// svg {
		// 	fill: black;

		// 	path {
		// 		fill: black;
		// 	}
		// }
	}

	.organizationHeader {
		padding-top: 2rem;
		padding-bottom: 2rem;


		display: flex;
		align-items: center;
		
		button {
			margin-left: 4rem;
		}
	}

	.organizationName {
		display: flex;
		align-items: center;

		.options {
			margin-left: 0.75rem;
			margin-bottom: 0.5rem;
			// font-size: 1.4rem;
		}
	}

	.organizationList {
		padding-left: 6rem;

		> li {
			margin: 1.5rem 0;

			h4 {
				padding-bottom: 0.25rem;
			}
		}
	}

	.projectList {
		display: grid;
		grid-template-columns: repeat(4, 1fr);
		gap: 3.5rem 5rem;

		padding-top: 1.5rem;
		padding-bottom: 4rem;

		li {
			overflow: hidden;
			
			display: flex;
			align-items: center;

			a {
				display: inline-block;

				background-color: $backgroundPrimary;

				font-size: 1.5rem;
			}

			a {
				width: 100%;
				height: 100%;

				padding: 1rem 1.5rem;

				// border: 1px solid #00000030;
				border-radius: 0.5rem;

				height: 7rem;

				overflow: hidden;

				&:hover {
					// background-color: #00000005;
					// color: $headingColor;
					h5, p {
						color: $hoverAccent;
					}
				}
			}
	
			p {
				line-height: 1.25;
			}
		}
	}

	h2 {
		display: flex;
		align-items: center;
		font-size: 2.5rem;
		padding-bottom: 1rem;
	}

	h3 {
		margin-left: 3rem;
		font-size: 2rem;
	}

	h4 {
		display: flex;
		align-items: center;
		font-size: 2rem;
		font-weight: normal;
		// padding-bottom: 1rem;
	}

	h5 {
		font-size: 1.6rem;
		font-weight: normal;
	}

	// .userAvatar,
	.organizationLogo,
	.IconBuilding {
		height: 3.5rem;
		// margin-left: 1rem;
		margin-right: 1.5rem;
	}

	.userAvatar {
		height: 5.5rem;
		width: 5.5rem;
		margin: 0.5rem;
		margin-left: 1.25rem;
		margin-right: 1rem;
	}

	.userAvatar, .IconBuilding {
		fill: $textColorWhite;

		path {
			fill: $textColorWhite;
		}
	}
}

