@import '../../../styles/scss/abstracts/variables';

.GridRow {
		position: relative;
		width: 100%;

		min-height: 3rem;

		// border: 1px solid purple;

		// border: 2px solid #FFF4;
		// border-top: 2px solid #FFF4;
		// border-bottom: 2px solid #FFF4;
		// background-color: #FFF4;

		// box-shadow: inset 0 0 12px #FFF8;

	&-container {
		display: flex;
		// padding-bottom: 1rem;

		position: relative;

		width: 100%;

		// border: 1px solid #0003;
		// margin-bottom: 1rem;

		&:last-child {
			margin-bottom: 0;
		}


		h2 {
			// writing-mode: vertical-lr;
			// transform: rotate(180deg);
			// width: 4rem;
			font-size: 1.6rem;
			text-align: center;

			display: flex;
			justify-content: center;
			align-items: center;

			text-transform: uppercase;
			text-align: left;

			padding-left: 1.5rem;
		}

		.title-area {
			display: flex;
			align-items: center;
			// flex-direction: column;
			// justify-content: center;

			// align-items: center;

			position: absolute;
			top: 0;

			width: 10%;
			// border: 1px solid red;


			.btn {
				// padding-bottom: 3.75rem;
				// margin-bottom: -7rem;
				// margin-left: 0.25rem;

				// position: relative;
				// top: -3.75rem;
				// right: -0.25rem;

				position: absolute;
				right: 1.5rem;

				// border: 1px solid red;

				// &-maximize {

				// }
				// &-minimize {

				// }
			}
		}

		.spacer {
			width: 4.2rem;
		}

	}

	h3 {
		padding: 0.8rem;
		font-size: 1.6rem;
	}

	&-content {
		// border: 1px solid red;
		width: 80%;
		left: calc(10% - 20px);
	}
}