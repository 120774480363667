@import-normalize; /* bring in normalize.css styles */

.clearfix:after {visibility: hidden; display: block; font-size: 0; content: ''; clear: both; height: 0; }

html { -moz-box-sizing: border-box; -webkit-box-sizing: border-box; box-sizing: border-box;}

*, *:before, *:after {box-sizing: inherit}

.sr-only { position: absolute; width: 1px; height: 1px; margin: -1px; border: 0; padding: 0; white-space: nowrap; clip-path: inset(100%); clip: rect(0 0 0 0); overflow: hidden;}


html {
	font-size: 62.5%;
}

body {
	font-size: 1.6rem;
}