@import '../../styles/scss/abstracts/variables';

.form {
	background-color: #e0dedc;

	width: 50%;
	min-width: 30rem;
	max-width: 60rem;
	// margin: 0 auto;
	// padding: 1.5rem;

	border-radius: 0.5rem;
	// box-shadow: 0 4px 6px #0002;

	display: flex;
	flex-direction: column;

	// margin-top: 8rem;

	h2 {
		color: $textColorGrey;
		font-weight: normal;
		margin-bottom: 0.25rem;
	}

	.inputs {
		display: grid;
		grid-template-columns: 1fr 1fr;
		gap: 1.25rem;

		width: 100%;
		margin: 1rem 0;

		.input {
			grid-column: span 2;

			&.span-1,
			&.radio {
				grid-column: span 1;
			}
	
			&.radio {
				display: flex;

				label {
					margin-right: 1rem;
				}
			}

			input:not([type="radio"]) {
				width: 100%;
				font-size: 1.6rem;
		
				padding: 1rem 2rem;
	
				// border: 1px solid #0001;
				border: none;
				border-radius: 0.5rem;
	
				font-size: 1.5rem;
	
				&::placeholder {
					text-transform: lowercase;
					color: #000B;
				}
			}
		}
	}

	.btn[type="submit"] {
		// margin: 1rem 0;

		margin-top: 2rem;
		align-self: flex-end;
	}

	.links {
		font-size: 1.4rem;
	}

	&-login,
	&-signup {
		margin: 0 auto;
		margin-top: 8rem;
		box-shadow: 0 4px 6px #0002;
		padding: 1.5rem;
	}

	.Link {
		color: #116482;
		font-size: 1.5rem;

		&:hover {
			color: lighten(#116482, 15);
		}
	}

	h3.profilePicture {
		margin-top: 0.5rem;
		margin-bottom: 1rem;
		font-size: 1.8rem;
		font-weight: normal;
		color: $textColorGrey;
	}
}

.ImageUpload {
	.btn-drag {
		// border: 1px solid grey;
		background-color: white;
		border-radius: 0.5rem;
		// padding: 0.5rem 1rem;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}

	.btn-control {
		padding-right: 1rem;
	}

	.default-avatar {
		width: 6rem;
	}

	.image-button {
		position: relative;
		
		&:hover {
			&::after {
				content: url(../../assets/images/icon-pencil-orange.svg);
				font-size: 1.4rem;
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				background-color: #FFFFFF55;
				color: white;
				display: flex;
				align-items: center;
				justify-content: center;


				border-radius: 50%;
			}
		}
	}

	.image-item {
		display: flex;
	}

	.image-item__btn-wrapper {
		display: flex;
		flex-direction: column;
		justify-content: center;

		margin-left: 3rem;

		.btn {
			height: 4.5rem;
			width: 15rem;

			&:first-child {
				margin-bottom: 1.25rem;
			}
		}
	}

	.IconDefault {
		width: 14rem;
		height: 14rem;

		fill: black;

		path {
			fill: black;
		}

		&-organization {
			padding: 3rem;
		}
	}

	.instruction {
		font-size: 1.4rem;

		width: 14rem;
		display: inline-block;

		margin-top: 1rem;
	}


}

.AvatarEditor {
	display: flex;
	align-items: center;

	.editor {
		border-radius: 0.5rem;
	}
	
	.controls {
		padding: 1rem;
	}

	.buttons {
		display: flex;
		flex-direction: column;
		justify-content: center;

		margin-left: 3rem;
		margin-bottom: 3rem;

		.btn {
			height: 4.5rem;
			width: 15rem;

			text-align: center;
			display: flex;
			justify-content: center;

			&:not(:last-child) {
				margin-bottom: 1.25rem;
			}

			&-save {
				font-size: 1.8rem;
				color: $hoverAccent;
			}
		}
	}

	.input {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 2rem;
		margin-top: 0.5rem;

		width: 25rem;

		input {
			width: 80%;
		}

		.IconMinus,
		.IconPlus {
			width: 1.6rem;
			height: 1.6rem;
		}

		label {
			margin-right: 1rem;
		}
	}
}

.ProjectForm {
	h3 {
		display: flex;
		align-items: center;
		margin-bottom: 1rem;

		img {
			width: 4rem;
			margin-right: 1rem;
		}
	}


}