.NestedGrid {
	// overflow: hidden;
	position: relative;

	// border: 1px solid red;

	// display: flex;
	// flex-direction: column;
	// justify-content: space-between;

	.btn {
		&-maximize {
			position: absolute;
			bottom: 1.25rem;
			left: 50%;
			transform: translateX(-50%);

			font-size: 1.6rem;
		}

		&-add {
			position: absolute;
			bottom: 1.25rem;
			right: 1rem;
			// transform: translateX(-50%);

			font-size: 1.6rem;
		}
	}
}