@import 'styles/scss/abstracts/variables';
// @import 'styles/scss/abstracts/mixins';

.Popup {
  background-color: $backgroundPrimary;
  // border: 1px solid grey;
  // padding: 1rem;

  position: absolute;
  // top: 50%;
  // left: calc(100% + 0.5rem);

  top: 0;
  left: 0;

  z-index: 1001;

  min-width: 22rem;

  max-width: 60rem;

  box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.25);

  font-size: 1.5rem;

  border-radius: 0.5rem;

  &[id*='Opportunity'] {
    .content {
      padding: 3rem;
    }
  }

  .property {
    padding-bottom: 0.5rem;

    &-label {
      padding-right: 0.5rem;
      // font-weight: 600;
    }

    &--challenges {
      padding-top: 1.5rem;
    }
  }

  .controls {
    position: absolute;
    z-index: 500;
    top: 0.4rem;
    right: 0.4rem;

    button {
      padding: 0 0.6rem;

      svg {
        height: 3rem;
      }
    }
  }

  .content {
    position: relative;
    padding: 5rem 5rem 3.5rem 3rem;
    // padding-right: 6rem;

    .title {
      white-space: nowrap;

      padding-bottom: 0.5rem;

      button {
        font-size: 1.8rem;

        &:hover {
          // text-decoration: underline;
          color: $hoverAccent;
        }
      }
    }
  }

  .btn {
    // padding: 0.1rem;

    // &:hover {
    // 	color: lighten($textColor, 20);
    // }

    // &-close {
    // 	position: absolute;
    // 	top: 0.4rem;
    // 	right: 0.4rem;
    // }

    // &-edit {
    // 	position: absolute;
    // 	top: 0.1rem;
    // 	right: 1.8rem;
    // }

    &-arrow {
      position: absolute;
      bottom: 0.4rem;
      right: 0.4rem;
      // transform:rotate(-45deg)

      font-size: 1.5rem;

      display: flex;
      align-items: center;

      svg {
        margin-left: 0.5rem;
      }
    }

    // &-move {
    // 	position: absolute;
    // 	top: 0.4rem;
    // 	right: 3.5rem;
    // }
  }

  .tags {
    display: flex;

    button {
      color: rgb(61, 99, 226);
      margin-right: 0.7rem;

      &:hover {
        color: lighten(rgb(61, 99, 226), 15);
      }
    }
  }

  .itemData {
    margin-top: 0.5rem;
    padding-left: 1.25rem;

    p {
      font-size: 1.4rem;
      white-space: nowrap;
      margin-right: 2rem;
    }
  }

  h4 {
    font-size: 1.5rem;
  }

  ul {
    list-style: disc inside;
    font-size: 1.4rem;

    li {
      margin-top: 0.2rem;
    }
  }
}
