@import '../../../styles/scss/abstracts/variables';

.ReactModal__Overlay {
	z-index: 1000;

	background: #0002 !important;
}

.ReactModal__Content {
	// background-color: $backgroundPrimary !important;
	border: none !important;
	border-radius: 0.5rem !important;
	box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.25);

	// width: 50%;
	overflow: visible !important;
	// overflow: auto !important;

	max-height: 95vh !important;

	padding: 2.5rem !important;

	.list {
		list-style: disc inside;
		font-size: 1.5rem;
		margin-left: 1rem;
		margin-bottom: 1rem;
	}

	.controls {
		z-index: 500;
		position: absolute;
		top: 0.4rem;
		right: 0.4rem;

		button {
			padding: 0 0.6rem;

			svg {
				height: 3rem;
			}
		}

		.btn-edit {
			display: none;
		}
	}

}

.Modal-content {
	h2 {
		margin-bottom: 0.5rem;
	}

	// .btn {
	// 	// color: $headingColor;

	// 	&:hover {
	// 		color: lighten($headingColor, 20);
	// 	}
	// 	// &-close {
	// 	// 	position: absolute;
	// 	// 	top: 1.5rem;
	// 	// 	right: 1.5rem;
	// 	// }
	// }

	.importData {
		display: flex;
		flex-direction: column;
		// display: block;

		// border: 1px solid red;

		// .msg-error {
		// 	display: block;
		// }
	}

	.IconImport {
		width: 6rem;
		height: 6rem;
	}

	.importInstruction {
		text-align: center;
		width: 100%;
		display: inline-block;
		margin-top: 1rem;
	}
}